// src/components/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import { useTranslation } from 'react-i18next';
import './Register.css';

const Register = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError(t('register.match'));
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/'); // Redirect to home page or any other page
    } catch (error) {
      const errorMessage = t(`register.errors.${error.code}`) || t('register.errors.default');
      setError(errorMessage);
    }
  };

  return (
    <div className="app__register section__padding">
      <h1 className="headtext__cormorant">{t('register.register')}</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="app__register-form">
        <input
          type="email"
          name="email"
          placeholder={t('register.email')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder={t('register.password')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          placeholder={t('register.confirmPassword')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={formData.confirmPassword}
          onChange={handleInputChange}
          required
        />
        <button type="submit" className="custom__button">{t('register.register')}</button>
      </form>
      <p className="p__opensans">
      {t('register.alreadyAccount')} <button className="link-button" onClick={() => navigate('/login')}>{t('register.login')}</button>
      </p>
    </div>
  );
};

export default Register;
