import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

const Newsletter = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const handleSubscribe = async () => {
    if (email) {
      try{
        await addDoc(collection(firestore, 'newsletter'), {
          email: email,
          subscribedAt: new Date(),
        });
        alert('Subscribed successfully!');
        setEmail('');
      } catch (error) {
        alert('Failed to subscribe. Please try again.');
      }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title={t("newsletter.heading")} />
      <h1 className="headtext__cormorant">{t("newsletter.title")}</h1>
      <p className="p__opensans">{t("newsletter.description")}</p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder={t("newsletter.placeholder")} value={email} onChange={(e) => setEmail(e.target.value)} />
      <button type="button" className="custom__button" onClick={handleSubscribe}>{t("newsletter.button_text")}</button>
    </div>
  </div>
);
};

export default Newsletter;