import React, { createContext, useReducer, useContext, useEffect } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = state.findIndex(item => item.productId === action.item.productId);
      if (existingItemIndex !== -1) {
        const updatedCart = [...state];
        updatedCart[existingItemIndex].quantity += 1;
        return updatedCart;
      } else {
        return [...state, { ...action.item, quantity: 1 }];
      }
    case 'DECREASE_QUANTITY':
      return state.map(item =>
        item.productId === action.id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      ).filter(item => item.quantity > 0);
    case 'REMOVE_FROM_CART':
      return state.filter(item => item.productId !== action.id);
    case 'CLEAR_CART':
      return [];
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const initialState = () => {
    const savedCart = localStorage.getItem('cart');
    try {
      return Array.isArray(JSON.parse(savedCart)) ? JSON.parse(savedCart) : [];
    } catch (e) {
      console.error("Could not parse cart from localStorage", e);
      return [];
    }
  };

  const [cart, dispatch] = useReducer(cartReducer, [], initialState);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
