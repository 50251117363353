import React, { useEffect, useState } from 'react';
import './Menu.css';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { firestore, storage } from '../../firebase';
import { useCart } from '../../contexts/CartContext';
import { format } from 'date-fns';
import { ro } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

const Menu = () => {
  const { i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [genericImage, setGenericImage] = useState('');
  const { dispatch } = useCart();
  const [dailymenuOpen, setDailymenuOpen] = useState('10:00');
  const [dailymenuClose, setDailymenuClose] = useState('22:00');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 842);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 842);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchDailyMenuTimes = async () => {
      const settingsDoc = doc(firestore, 'settings', 'working_hours');
      const docSnap = await getDoc(settingsDoc);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDailymenuOpen(data.dailymenu_open || '10:00');
        setDailymenuClose(data.dailymenu_close || '14:00');
      }
    };

    fetchDailyMenuTimes();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'menu'));
      const categoriesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const sortedCategories = categoriesData.sort((a, b) => a.order - b.order);

      const todayDate = format(new Date(), 'dd-MM-yyyy', { locale: ro });
      const dailyMenuDoc = await getDoc(doc(firestore, 'dailymenu', todayDate));
      if (dailyMenuDoc.exists() && !sortedCategories.some(cat => cat.id === 'meniul_zilei')) {
        sortedCategories.unshift({ id: 'meniul_zilei', name: 'Meniul Zilei', order: 0 });
      }

      setCategories(sortedCategories);
      if (!selectedCategory && sortedCategories.length > 0) {
        setSelectedCategory(sortedCategories[0].id);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      if (selectedCategory === 'meniul_zilei') {
        const todayDate = format(new Date(), 'dd-MM-yyyy', { locale: ro });
        const dailyMenuDoc = await getDoc(doc(firestore, 'dailymenu', todayDate));
        if (dailyMenuDoc.exists()) {
          const now = new Date();
          const currentTime = format(now, 'HH:mm');

          const data = dailyMenuDoc.data();
          const dailyMenuItems = [
            { id: 'varianta1', title: 'VARIANTA 1', description: data.varianta1, price: 30, order: 1, photo: genericImage },
            { id: 'varianta2', title: 'VARIANTA 2', description: data.varianta2, price: 30, order: 2, photo: genericImage },
            { id: 'varianta3', title: 'VARIANTA 3', description: data.varianta3, price: 30, order: 3, photo: genericImage },
          ];
          setProducts(dailyMenuItems);
        }
      } else if (selectedCategory) {
        const productsCollection = collection(firestore, 'menu', selectedCategory, 'products');
        const querySnapshot = await getDocs(productsCollection);
        let productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        productsData = productsData.sort((a, b) => a.order - b.order);
        setProducts(productsData);
      }
    };

    fetchProducts();
  }, [selectedCategory, genericImage, dailymenuOpen, dailymenuClose]);

  useEffect(() => {
    const fetchGenericImage = async () => {
      const genericImageRef = ref(storage, 'products/generic.avif');
      const url = await getDownloadURL(genericImageRef);
      setGenericImage(url);
    };

    fetchGenericImage();
  }, []);

  const addToCart = (item) => {
    const cartItem = {
      categoryId: selectedCategory,
      productId: item.id,
      title: item.title,
      price: item.price,
      photo: item.photo,
      description: item.description,
      size: item.size
    };
    dispatch({ type: 'ADD_TO_CART', item: cartItem });
  };

  if (!isMobile) {
    return (
      <div className="app__menu app__bg section__padding">
        <div className="app__menu-categories">
          <h2 className="headtext__cormorant">Categories</h2>
          <ul>
            {categories.map((category) => (
              <li
                key={category.id}
                className={category.id === selectedCategory ? 'selected' : ''}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
                <div className="underline"></div>
              </li>
            ))}
          </ul>
        </div>
        <div className="app__menu-items">
          {products.map((item) => (
            <div className="app__menu-item-card" key={item.id}>
              <img src={item.photo || genericImage} alt={item.title} />
              <div className="content">
                <div className="details">
                  <h3 className="p__cormorant">{item.title}</h3>
                  <p className="p__opensans" dangerouslySetInnerHTML={{__html: item.description.replace(/\n/g, '<br />') }}></p>
                  <p className="p__opensans size">{item.size}</p>
                </div>
                <div className="footer">
                  <p className="p__opensans">{item.price} RON</p>
                  <button className="custom__button" onClick={() => addToCart(item)}>{i18n.language === 'en' ? 'Add to Cart' : 'Adaugă în coș'}</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="app__menu app__bg section__padding">
        <div className="app__menu-categories">
          <h2 className="headtext__cormorant">Categories</h2>
          <ul>
            {categories.map((category) => (
              <li
                key={category.id}
                className={category.id === selectedCategory ? 'selected' : ''}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
                <div className="underline"></div>
                {/* Display products directly under the selected category */}
                {selectedCategory === category.id && (
                  <div className="app__menu-items">
                    {products.map((item) => (
                      <div className="app__menu-item-card" key={item.id}>
                        <img src={item.photo || genericImage} alt={item.title} />
                        <div className="content">
                          <div className="details">
                            <h3 className="p__cormorant">{item.title}</h3>
                            <p className="p__opensans" dangerouslySetInnerHTML={{__html: item.description.replace(/\n/g, '<br />') }}></p>
                            <p className="p__opensans size">{item.size}</p>
                          </div>
                          <div className="footer">
                            <p className="p__opensans">{item.price} RON</p>
                            <button
                              className="custom__button"
                              onClick={() => addToCart(item)}
                            >
                              {i18n.language === 'en' ? 'Add to Cart' : 'Adaugă în coș'}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

    )
  }
};

export default Menu;
