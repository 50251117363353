import React, { useState, useEffect } from 'react';
import { MdOutlineAccountCircle, MdOutlineHistory, MdExitToApp } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Account.css'; // Ensure to style accordingly in CSS
import { getDoc, setDoc, collection, getDocs, doc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';

const validateAddress = async (fullAddress) => {
  const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=AIzaSyBrVREIUlL2ASPtHrpy5iJp-8HM58ysyqA`;
  try {
    const response = await fetch(geocodingApiUrl);
    const data = await response.json();

    if (data.status === 'OK') {
      const location = data.results[0].geometry.location;
      const lat = location.lat;
      const lng = location.lng;

      const bounds = {
        north: 45.828512,
        south: 45.712049,
        east: 21.320888,
        west: 21.174034,
      };

      const withinBounds = lat <= bounds.north && lat >= bounds.south && lng <= bounds.east && lng >= bounds.west;
      return withinBounds;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error validating address:', error);
    return false;
  }
};

const Account = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('general');

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error(t('account.messages.errorSigningOut'));
    }
  };

  return (
    <div className="app__account section__padding">
      <div className="account__menu">
        <div className="account__header">
          <FaUserCircle size={50} />
          <h2>{t('account.myAccount')}</h2>
        </div>
        <div
          onClick={() => setActiveTab('general')}
          className={`menu-item ${activeTab === 'general' ? 'active_option' : ''}`}
        >
          <MdOutlineAccountCircle size={24} />
          <span>{t('account.general')}</span>
        </div>
        <div
          onClick={() => setActiveTab('orders')}
          className={`menu-item ${activeTab === 'orders' ? 'active_option' : ''}`}
        >
          <MdOutlineHistory size={24} />
          <span>{t('account.orders')}</span>
        </div>
        <div onClick={handleLogout} className="menu-item logout">
          <MdExitToApp size={24} />
          <span>{t('account.logout')}</span>
        </div>
      </div>
      <div className="account__details">
        {activeTab === 'general' && <GeneralForm />}
        {activeTab === 'orders' && <Orders />}
      </div>
    </div>
  );
};

const GeneralForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    street: '',
    number: '',
    localitate: '',
    apartment: '', // Optional field for apartment
  });
  const [loading, setLoading] = useState(true);
  const userId = auth.currentUser.uid;

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = doc(firestore, 'accounts', userId);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        setFormData(docSnap.data());
      }
      setLoading(false);
    };

    fetchUserData();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDoc = doc(firestore, 'accounts', userId);

    try {
      const fullAddress = `${formData.street} ${formData.number}, ${formData.apartment ? formData.apartment + ', ' : ''}${formData.localitate}`;
      const isValid = await validateAddress(fullAddress);

      if (isValid) {
        await setDoc(userDoc, { ...formData, address: fullAddress }, { merge: true });
        alert(t('account.messages.profileUpdated'));
      } else {
        alert(t('account.messages.invalidAddress'));
      }
    } catch (error) {
      console.error(t('account.messages.errorUpdatingProfile'));
    }
  };

  if (loading) {
    return <p>{t('account.loading')}</p>;
  }

  return (
    <div>
      <h2 className="headtext__cormorant">{t('account.generalInformation')}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{t('account.form.name')}</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('account.form.phone')}</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('account.form.street')}</label>
          <input
            type="text"
            name="street"
            value={formData.street}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('account.form.number')}</label>
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>{t('account.form.apartment')}</label>
          <input
            type="text"
            name="apartment"
            value={formData.apartment}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>{t('account.form.localitate')}</label>
          <input
            type="text"
            name="localitate"
            value={formData.localitate}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="custom__button">{t('account.form.saveChanges')}</button>
      </form>
    </div>
  );
};

const Orders = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const userId = auth.currentUser?.uid;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersCollectionRef = collection(firestore, 'accounts', userId, 'orders');
        const ordersSnapshot = await getDocs(ordersCollectionRef);
        const ordersData = [];

        for (const document of ordersSnapshot.docs) {
          const orderDate = document.id;
          const orderRefs = document.data();

          for (const orderId in orderRefs) {
            console.log(orderDate, orderId);
            const orderDocRef = doc(firestore, 'orders', orderDate, 'orders', orderId);
            const orderDocSnapshot = await getDoc(orderDocRef);

            if (orderDocSnapshot.exists()) {
              ordersData.push({
                orderId,
                date: orderDate,
                ...orderDocSnapshot.data()
              });
            }
          }
        }

        // Sort orders by date (DD-MM-YYYY) and then by createdAt (HH:MM)
        ordersData.sort((a, b) => {
          // Parse the dates from DD-MM-YYYY format to Date objects for comparison
          const dateA = new Date(a.date.split('-').reverse().join('-'));
          const dateB = new Date(b.date.split('-').reverse().join('-'));

          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;

          // If the dates are the same, compare the createdAt times
          const timeA = a.createdAt.split(':').map(Number);
          const timeB = b.createdAt.split(':').map(Number);
          const totalMinutesA = timeA[0] * 60 + timeA[1];
          const totalMinutesB = timeB[0] * 60 + timeB[1];

          return totalMinutesB - totalMinutesA;
        });
        
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchOrders();
  }, [userId]);

  if (loading) {
    return <p>{t('account.loading')}</p>
  }
  return (
    <div>
      <h2 className="headtext__cormorant">{t('account.ordersSection.yourOrders')}</h2>
      {orders.length === 0 ? (
        <p>{t('account.ordersSection.noOrders')}</p>
      ) : (
        <div className="orders-list">
          {orders.map((order) => (
            <div key={order.orderId} className="order-card">
              <h3>{t('account.ordersSection.order')} #{order.orderId}</h3>
              <p>{t('account.ordersSection.date')}: {order.date}</p>
              <p>{t('account.ordersSection.totalPrice')}: {order.totalPrice} RON</p>
              <p>{t('account.ordersSection.status')}:
                <span style={{ color: order.status === -1 ? 'red' : 'inherit' }}>
                  {order.status === -1 && t('account.ordersSection.canceled')}
                  {order.status === 0 && t('account.ordersSection.processing')}
                  {order.status === 1 && t('account.ordersSection.accepted')}
                  {order.status === 2 && t('account.ordersSection.preparing')}
                  {order.status === 3 && t('account.ordersSection.cooking')}
                  {order.status === 4 && t('account.ordersSection.delivering')}
                  {order.status === 5 && t('account.ordersSection.finished')}
                </span>
              </p>
              <div className="order-items">
                {order.items.map((item, index) => (
                  <div key={index} className="order-item">
                    <img src={item.photo} alt={item.title} />
                    <div>
                      <p>{item.title}</p>
                      <p>{t('account.ordersSection.quantity')}: {item.quantity}</p>
                      <p>{t('account.ordersSection.price')}: {item.price} RON</p>
                      <p>{t('account.ordersSection.size')}: {item.size}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Account;
