import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import General from './General';
import MeniulZilei from './MeniulZilei';
import Meniu from './Meniu';
import Comenzi from './Comenzi';
import Soferi from './Soferi';
import Conturi from './Conturi';
import Setari from './Setari';
import OrderDetails from './OrderDetails';
import AccountDetails from './AccountDetails';
import './Admin.css';

const Admin = () => {
  const location = useLocation();

  return (
    <div className="admin">
      <nav className="admin__sidebar">
        <ul>
          <li className={location.pathname === "/admin/general" ? "active" : ""}>
            <Link to="general">General</Link>
          </li>
          <li className={location.pathname === "/admin/meniul-zilei" ? "active" : ""}>
            <Link to="meniul-zilei">Meniul Zilei</Link>
          </li>
          <li className={location.pathname === "/admin/meniu" ? "active" : ""}>
            <Link to="meniu">Meniu</Link>
          </li>
          <li className={location.pathname.startsWith("/admin/comenzi") ? "active" : ""}>
            <Link to="comenzi">Comenzi</Link>
          </li>
          <li className={location.pathname === "/admin/soferi" ? "active" : ""}>
            <Link to="soferi">Soferi</Link>
          </li>
          <li className={location.pathname === "/admin/conturi" ? "active" : ""}>
            <Link to="conturi">Conturi</Link>
          </li>
          <li className={location.pathname === "/admin/setari" ? "active" : ""}>
            <Link to="setari">Setari</Link>
          </li>
        </ul>
      </nav>
      <div className="admin__content">
        <Routes>
          <Route path="general" element={<General />} />
          <Route path="meniul-zilei" element={<MeniulZilei />} />
          <Route path="meniu" element={<Meniu />} />
          <Route path="comenzi" element={<Comenzi />} />
          <Route path="soferi" element={<Soferi />} />
          <Route path="conturi" element={<Conturi />} />
          <Route path="setari" element={<Setari />} />
          <Route path="comenzi/:date/:orderId" element={<OrderDetails />} />
          <Route path="conturi/:accountId" element={<AccountDetails />} />
        </Routes>
      </div>
    </div>
  );
};

export default Admin;
