import React from 'react';
import { images } from "../../constants";
import { SubHeading } from "../../components";
import { useTranslation } from 'react-i18next';
import './Header.css';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  const { t } = useTranslation();

  return (

  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title={t('header.subheading')} />
      <h1 className="app__header-h1">{t('header.heading')}</h1>
      <p className="p__opensans" style={{margin: '2rem 0', textTransform: 'none'}}>{t('header.description')}</p>
      <Link smooth to="/menu">
        <button type="button" className="custom__button">{t('header.explore')}</button>
      </Link>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" />
    </div>
  </div>
);

};

export default Header;
