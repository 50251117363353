import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import AppWrapper from './App';
import './i18n';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const container = document.getElementById('root'); // Get the root element
const root = createRoot(container); // Create a root

root.render(<AppWrapper />); // Render the app
