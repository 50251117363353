import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { auth } from '../../firebase';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t('forgotPassword.sendSuccessfully'));
    } catch (error) {
      const errorMessage = t(`forgotPassword.errors.${error.code}`) || t('forgotPassword.errors.default');
      setError(errorMessage);
    }
  };

  return (
    <div className="app__forgotpassword section__padding">
      <h1 className="headtext__cormorant">{t('forgotPassword.forgotPassword')}</h1>
      {message && <p className="message">{message}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="app__forgotpassword-form">
        <input
          type="email"
          name="email"
          placeholder={t('forgotPassword.email')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" className="custom__button">{t('forgotPassword.sendEmail')}</button>
      </form>
      <p className="p__opensans">
        {t('forgotPassword.remembered')} <button className="link-button" onClick={() => navigate('/login')}>{t('forgotPassword.login')}</button>
      </p>
    </div>
  );
};

export default ForgotPassword;