// src/Meniu.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, query, orderBy, getDoc } from "firebase/firestore";
import { firestore, storage } from '../../firebase';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { AiFillEdit, AiFillDelete, AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { uploadBytes, getDownloadURL, ref, deleteObject, uploadBytesResumable } from 'firebase/storage';
import jsPDF from 'jspdf';
import { imgBase64, cormorantBase64 } from './base64Vars';
import './Meniu.css';
import Modal from 'react-modal';

const Meniu = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editMode, setEditMode] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      const q = query(collection(firestore, "menu"), orderBy("order"));
      const querySnapshot = await getDocs(q);
      const categoriesData = [];
      querySnapshot.forEach((doc) => {
        const categoryData = doc.data();
        categoryData.id = doc.id;
        categoriesData.push(categoryData);
      });
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const addCategory = async (e) => {
    e.preventDefault();
    if (newCategoryName.trim() === "") return;

    const docRef = await addDoc(collection(firestore, "menu"), {
      name: newCategoryName,
      order: categories.length + 1
    });

    setCategories([...categories, { id: docRef.id, name: newCategoryName, order: categories.length + 1 }]);
    setNewCategoryName("");
  };

  const deleteCategory = async (categoryId) => {
    if (window.confirm("Are you sure you want to delete this category and all its products?")) {
      await deleteDoc(doc(firestore, "menu", categoryId));
      setCategories(categories.filter(category => category.id !== categoryId));
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(categories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCategories(items);

    for (let i = 0; i < items.length; i++) {
      await updateDoc(doc(firestore, "menu", items[i].id), { order: i + 1 });
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  }

  const generateMenuPDF = async () => {
    try {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      doc.addFileToVFS("Cormorant-Regular.ttf", cormorantBase64);
      doc.addFont("Cormorant-Regular.ttf", "Cormorant", "normal");

      doc.setFont("Cormorant", "normal");

      
      doc.addImage(imgBase64, 'JPEG', 0, 0, pageWidth, pageHeight);
      console.log('Loading image...');
      // Ensure the image is loaded before drawing it
      console.log('Image is loaded!')
      // Draw the background image on the first page

      let yPosition = 100;

      // Set the title
      doc.setFontSize(30);
      doc.setTextColor(220, 202, 135); // Set to the same color as --color-golden
      doc.text('Meniu Genesa', 50, 50);

      // Fetch categories from Firestore
      const categoriesSnapshot = await getDocs(query(collection(firestore, 'menu'), orderBy('order')));
      for (const categoryDoc of categoriesSnapshot.docs) {
        const category = categoryDoc.data();

        // Set category title
        doc.setFontSize(24);
        doc.setTextColor(220, 202, 135); // Set to the same color as --color-golden
        doc.text(category.name, 50, yPosition);

        yPosition += 30;

        // Fetch products for this category
        const productsSnapshot = await getDocs(query(collection(firestore, `menu/${categoryDoc.id}/products`), orderBy('order')));

        for (const productDoc of productsSnapshot.docs) {
          const product = productDoc.data();

          const titleAndPriceText = `${product.title} - ${product.price} RON`;
          const splitTitleAndPriceText = doc.splitTextToSize(titleAndPriceText, pageWidth - 140);
          // Product title
          doc.setFontSize(18);
          doc.setTextColor(255, 255, 255); // Set to white
          doc.text(splitTitleAndPriceText, 70, yPosition);

          yPosition += splitTitleAndPriceText.length * 18 + 10;

          // Product description
          const descriptionLines = doc.splitTextToSize(product.description, pageWidth - 140);
          doc.setFontSize(12);
          doc.text(descriptionLines, 70, yPosition);

          yPosition += descriptionLines.length * 12 + 10;

          if (yPosition > pageHeight - 50) {
            doc.addPage();
            doc.addImage(imgBase64, 'JPEG', 0, 0, pageWidth, pageHeight); // Add the background to the new page
            yPosition = 50;
          }
        }

        yPosition += 20;
      }

      // Save the PDF and get the download URL
      const pdfBytes = doc.output('blob');
      const fileName = `menu/menu.pdf`;
      const fileRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(fileRef, pdfBytes, { contentType: 'application/pdf' });

      uploadTask.on('state_changed',
        null,
        (error) => {
          alert('Meniul a avut o eroare.');
        },
        async () => {
          alert("Meniul s-a creat cu succes!");
        }
      );
    } catch (error) {
      alert("Meniul a avut o eroare");
    }
  };

  return (
    <div className="meniu">
      <div className="meniu-header">
        <form onSubmit={addCategory}>
          <input
            type="text"
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
            placeholder="Add new category"
          />
          <button type="submit">Add Category</button>
        </form>
        <button onClick={generateMenuPDF} className="generate-pdf-button">
          Actualizează meniul
        </button>
        <div className="edit-mode-toggle">
          <span
            className={`toggle-text ${editMode ? 'active' : ''}`}
            onClick={() => setEditMode(true)}
          >
            Editează Categorii
          </span>
          /
          <span
            className={`toggle-text ${!editMode ? 'active' : ''}`}
            onClick={() => setEditMode(false)}
          >
            Editează Produse
          </span>
        </div>
      </div>
      <DragDropContext onDragEnd={editMode ? handleDragEnd : undefined}>
        <Droppable droppableId="categories">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {categories.map((category, index) => (
                <Draggable key={category.id} draggableId={category.id} index={index} isDragDisabled={!editMode}>
                  {(provided) => (
                    <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Category category={category} deleteCategory={deleteCategory} editMode={editMode} />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const Category = ({ category, deleteCategory, editMode }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(category.name);
  const [isExpanded, setIsExpanded] = useState(false);
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({
    title: "",
    price: "",
    size: "",
    description: "",
    photo: ""
  });

  useEffect(() => {
    if (isExpanded) {
      const fetchProducts = async () => {
        const productsCollection = collection(firestore, `menu/${category.id}/products`);
        const q = query(productsCollection, orderBy("order"));
        const productsSnapshot = await getDocs(q);
        const productsData = [];
        productsSnapshot.forEach((doc) => {
          const productData = doc.data();
          productData.id = doc.id;
          productsData.push(productData);
        });
        setProducts(productsData);
      };

      fetchProducts();
    }
  }, [isExpanded, category.id]);

  useEffect(() => {
    const fetchGenericPhotoURL = async () => {
      const storageRef = ref(storage, 'products/generic.avif');
      const photoURL = await getDownloadURL(storageRef);
      setNewProduct((prevState) => ({ ...prevState, photo: photoURL }));
    };

    fetchGenericPhotoURL();
  }, []);

  const saveCategory = async () => {
    await updateDoc(doc(firestore, "menu", category.id), { name: newName });
    setIsEditing(false);

    category.name = newName;
  };

  const addProduct = async (e) => {
    e.preventDefault();
    if (newProduct.title.trim() === "") return;

    const newOrder = products.length + 1
    const productWithOrder = { ...newProduct, order: newOrder };

    const docRef = await addDoc(collection(firestore, `menu/${category.id}/products`), productWithOrder);
    setProducts([...products, { ...productWithOrder, id: docRef.id }]);
    setNewProduct({ title: "", price: "", size: "", description: "", photo: newProduct.photo });
  };

  const deleteProduct = async (productId) => {
    const productRef = doc(firestore, `menu/${category.id}/products`, productId);
    const productSnapshot = await getDoc(productRef);
    const productData = productSnapshot.data();

    if (productData.photo !== newProduct.photo) {
      const storageRef = ref(storage, productData.photo);
      await deleteObject(storageRef);
    }

    await deleteDoc(productRef);
    setProducts(products.filter(product => product.id !== productId));
  };

  const handleImageUpload = async (e, productId) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `products/${productId}`);
    await uploadBytes(storageRef, file);
    const photoURL = await getDownloadURL(storageRef);

    const productRef = doc(firestore, `menu/${category.id}/products`, productId);
    await updateDoc(productRef, { photo: photoURL });

    setProducts(products.map(product => (product.id === productId ? { ...product, photo: photoURL } : product)));
  };

  const saveProductField = async (productId, field, value) => {
    const productRef = doc(firestore, `menu/${category.id}/products`, productId);
    await updateDoc(productRef, { [field]: value });
    setProducts(products.map(product => (product.id === productId ? { ...product, [field]: value } : product)));
  };

  const moveProduct = async (productId, direction) => {
    // Get the current product list and their order
    const productIndex = products.findIndex(p => p.id === productId);
    if (productIndex === -1) return;

    const newProducts = [...products];

    if (direction === 'up' && productIndex > 0) {
      [newProducts[productIndex], newProducts[productIndex - 1]] = [newProducts[productIndex - 1], newProducts[productIndex]];
    } else if (direction === 'down' && productIndex < newProducts.length - 1) {
      [newProducts[productIndex], newProducts[productIndex + 1]] = [newProducts[productIndex + 1], newProducts[productIndex]];
    } else {
      return;
    }

    // Update the state and database with the new order
    setProducts(newProducts);

    // Updating the order in the database
    for (let i = 0; i < newProducts.length; i++) {
      await updateDoc(doc(firestore, `menu/${category.id}/products`, newProducts[i].id), { order: i + 1 });
    }
  };


  return (
    <div className="category">
      {isEditing ? (
        <div className="category-edit">
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <button onClick={saveCategory}>✓</button>
        </div>
      ) : (
        <h2 className={`category-header ${editMode ? 'edit-mode' : ''}`}>
          {editMode ? (
            <>
              <span className="reorder-handle">::: </span>
              {newName}
              <div className="category-actions">
                <AiFillEdit onClick={() => setIsEditing(true)} className="edit-icon" />
                <AiFillDelete onClick={() => deleteCategory(category.id)} className="delete-icon" />
              </div>
            </>
          ) : (
            <>
              {newName}
              <div className="category-actions">
                {isExpanded ? (
                  <AiOutlineArrowUp onClick={() => setIsExpanded(false)} />
                ) : (
                  <AiOutlineArrowDown onClick={() => setIsExpanded(true)} />
                )}
              </div>
            </>
          )}
        </h2>
      )}
      {isExpanded && (
        <div className="products-list">
          <form onSubmit={addProduct}>
            <input
              type="text"
              value={newProduct.title}
              onChange={(e) => setNewProduct({ ...newProduct, title: e.target.value })}
              placeholder="Nume Produs"
            />
            <input
              type="text"
              value={newProduct.price}
              onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
              placeholder="Pret"
            />
            <input
              type="text"
              value={newProduct.description}
              onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
              placeholder="Descriere"
            />
            <input
              type="text"
              value={newProduct.size}
              onChange={(e) => setNewProduct({ ...newProduct, size: e.target.value })}
              placeholder="Marime"
            />
            <button type="submit">Adauga Produs</button>
          </form>
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Price</th>
                <th>Description</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <ProductRow key={product.id} product={product} handleImageUpload={handleImageUpload} saveProductField={saveProductField} deleteProduct={deleteProduct} moveProduct={moveProduct} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const ProductRow = ({ product, handleImageUpload, saveProductField, deleteProduct, moveProduct }) => {
  const [isEditing, setIsEditing] = useState({ title: false, price: false, description: false, size: false });
  const [editedValue, setEditedValue] = useState({ title: product.title, price: product.price, description: product.description, size: product.size });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalFields, setModalFields] = useState({ allergens: product.allergens, macronutrients: product.macronutrients });

  const handleEditClick = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleBlur = (field) => {
    saveProductField(product.id, field, editedValue[field]);
    setIsEditing({ ...isEditing, [field]: false });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveModalFields = () => {
    saveProductField(product.id, 'allergens', modalFields.allergens);
    saveProductField(product.id, 'macronutrients', modalFields.macronutrients);
    closeModal();
  };

  return (
    <>
      <tr>
        <td>
          <div className="product-image-wrapper">
            <img src={product.photo} alt={product.title} className="product-image" />
            <div className="product-image-overlay">
              <AiFillEdit onClick={() => document.getElementById(`file-input-${product.id}`).click()} />
              <input
                type="file"
                id={`file-input-${product.id}`}
                style={{ display: 'none' }}
                onChange={(e) => handleImageUpload(e, product.id)}
              />
            </div>
          </div>
        </td>
        <td>
          {isEditing.title ? (
            <input
              type="text"
              value={editedValue.title}
              onChange={(e) => setEditedValue({ ...editedValue, title: e.target.value })}
              onBlur={() => handleBlur('title')}
            />
          ) : (
            <span onClick={() => handleEditClick('title')}>{product.title}</span>
          )}
        </td>
        <td>
          {isEditing.price ? (
            <input
              type="text"
              value={editedValue.price}
              onChange={(e) => setEditedValue({ ...editedValue, price: e.target.value })}
              onBlur={() => handleBlur('price')}
            />
          ) : (
            <span onClick={() => handleEditClick('price')}>{product.price}</span>
          )}
        </td>
        <td>
          {isEditing.description ? (
            <input
              type="text"
              value={editedValue.description}
              onChange={(e) => setEditedValue({ ...editedValue, description: e.target.value })}
              onBlur={() => handleBlur('description')}
            />
          ) : (
            <span onClick={() => handleEditClick('description')}>{product.description}</span>
          )}
        </td>
        <td>
          {isEditing.size ? (
            <input
              type="text"
              value={editedValue.size}
              onChange={(e) => setEditedValue({ ...editedValue, size: e.target.value })}
              onBlur={() => handleBlur('size')}
            />
          ) : (
            <span onClick={() => handleEditClick('size')}>{product.size}</span>
          )}
        </td>
        <td>
          <div className="product-actions">
            <AiOutlineArrowUp onClick={() => moveProduct(product.id, 'up')} />
            <AiOutlineArrowDown onClick={() => moveProduct(product.id, 'down')} />
            <AiFillEdit onClick={openModal} />
            <AiFillDelete onClick={() => {
              if (window.confirm('Are you sure you want to delete this product?')) {
                deleteProduct(product.id);
              }
            }} />
          </div>
        </td>
      </tr>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Product"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Edit Product</h2>
        <label>
          Allergens:
          <input
            type="text"
            value={modalFields.allergens}
            onChange={(e) => setModalFields({ ...modalFields, allergens: e.target.value })}
          />
        </label>
        <label>
          Macronutrients:
          <input
            type="text"
            value={modalFields.macronutrients}
            onChange={(e) => setModalFields({ ...modalFields, macronutrients: e.target.value })}
          />
        </label>
        <button onClick={saveModalFields}>Save</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal>
    </>
  );
};

export default Meniu;
