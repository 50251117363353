// src/Soferi.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { firestore } from '../../firebase';
import './Soferi.css';

const Soferi = () => {
  const [drivers, setDrivers] = useState([]);
  const [newDriver, setNewDriver] = useState({ name: "", phone: "" });

  useEffect(() => {
    const fetchDrivers = async () => {
      const driversCollection = collection(firestore, "drivers");
      const driversSnapshot = await getDocs(driversCollection);
      const driversData = [];
      driversSnapshot.forEach((doc) => {
        const driverData = doc.data();
        driverData.id = doc.id;
        driversData.push(driverData);
      });
      setDrivers(driversData);
    };

    fetchDrivers();
  }, []);

  const addDriver = async (e) => {
    e.preventDefault();
    if (newDriver.name.trim() === "" || newDriver.phone.trim() === "") return;

    const docRef = await addDoc(collection(firestore, "drivers"), newDriver);
    setDrivers([...drivers, { id: docRef.id, ...newDriver }]);
    setNewDriver({ name: "", phone: "" });
  };

  const deleteDriver = async (driverId) => {
    if (window.confirm("Are you sure you want to delete this driver?")) {
      await deleteDoc(doc(firestore, "drivers", driverId));
      setDrivers(drivers.filter(driver => driver.id !== driverId));
    }
  };

  const updateDriver = async (driverId, updatedData) => {
    await updateDoc(doc(firestore, "drivers", driverId), updatedData);
    setDrivers(drivers.map(driver => (driver.id === driverId ? { ...driver, ...updatedData } : driver)));
  };

  return (
    <div className="soferi">
      <h1>Drivers</h1>
      <form onSubmit={addDriver}>
        <input
          type="text"
          value={newDriver.name}
          onChange={(e) => setNewDriver({ ...newDriver, name: e.target.value })}
          placeholder="Driver name"
        />
        <input
          type="text"
          value={newDriver.phone}
          onChange={(e) => setNewDriver({ ...newDriver, phone: e.target.value })}
          placeholder="Phone number"
        />
        <button type="submit">Add Driver</button>
      </form>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {drivers.map(driver => (
            <DriverRow key={driver.id} driver={driver} deleteDriver={deleteDriver} updateDriver={updateDriver} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DriverRow = ({ driver, deleteDriver, updateDriver }) => {
  const [isEditing, setIsEditing] = useState({ name: false, phone: false });
  const [editedValue, setEditedValue] = useState({ name: driver.name, phone: driver.phone });

  const handleEditClick = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleBlur = (field) => {
    updateDriver(driver.id, { [field]: editedValue[field] });
    setIsEditing({ ...isEditing, [field]: false });
  };

  return (
    <tr>
      <td>
        {isEditing.name ? (
          <input
            type="text"
            value={editedValue.name}
            onChange={(e) => setEditedValue({ ...editedValue, name: e.target.value })}
            onBlur={() => handleBlur('name')}
          />
        ) : (
          <span onClick={() => handleEditClick('name')}>{driver.name}</span>
        )}
      </td>
      <td>
        {isEditing.phone ? (
          <input
            type="text"
            value={editedValue.phone}
            onChange={(e) => setEditedValue({ ...editedValue, phone: e.target.value })}
            onBlur={() => handleBlur('phone')}
          />
        ) : (
          <span onClick={() => handleEditClick('phone')}>{driver.phone}</span>
        )}
      </td>
      <td>
        <button onClick={() => deleteDriver(driver.id)}>Delete</button>
      </td>
    </tr>
  );
};

export default Soferi;
