import React from 'react';
import './Privacy.css';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t } = useTranslation();

    return (
        <div className="privacy__container">
            <h1 className="privacy__title">{t('privacy.title')}</h1>
            <div className="privacy__content">
                
                <h2>{t('privacy.purpose.title')}</h2>
                <p>{t('privacy.purpose.content')}</p>

                <h2>{t('privacy.scope.title')}</h2>
                <p>{t('privacy.scope.content')}</p>

                <h2>{t('privacy.definitions.title')}</h2>
                <ul>
                    <li>{t('privacy.definitions.operator')}</li>
                    <li>{t('privacy.definitions.health_data')}</li>
                    <li>{t('privacy.definitions.data_subject')}</li>
                    <li>{t('privacy.definitions.personal_data')}</li>
                    <li>{t('privacy.definitions.data_breach')}</li>
                    <li>{t('privacy.definitions.processing')} </li>
                    <li>{t('privacy.definitions.processor')}</li>
                    <li>{t('privacy.definitions.pseudonymization')}</li>
                    <li>{t('privacy.definitions.recipient')} </li>
                    <li>{t('privacy.definitions.restriction')} </li>
                </ul>

                <h2>{t('privacy.operator_identification.title')}</h2>
                <p>{t('privacy.operator_identification.content')}</p>

                <h2>{t('privacy.principles.title')}</h2>
                <ul>
                    <li>{t('privacy.principles.lawfulness_fairness')} </li>
                    <li>{t('privacy.principles.transparency')} </li>
                    <li>{t('privacy.principles.purpose_limitation')} </li>
                    <li>{t('privacy.principles.data_minimization')}</li>
                    <li>{t('privacy.principles.accuracy')} </li>
                    <li>{t('privacy.principles.storage_limitation')} </li>
                    <li>{t('privacy.principles.integrity_confidentiality')}</li>
                    <li>{t('privacy.principles.accountability')}</li>
                </ul>

                <h2>{t('privacy.data_subject_information.title')}</h2>
                <ul>
                    <li>{t('privacy.data_subject_information.contract_management')}</li>
                    <li>{t('privacy.data_subject_information.marketing_management')} </li>
                </ul>

                <h2>{t('privacy.recipients.title')}</h2>
                <ul>
                    <li>{t('privacy.recipients.content')}</li>
                </ul>

                <h2>{t('privacy.data_subject_rights.title')}</h2>
                <ul>
                    {t('privacy.data_subject_rights.content', { returnObjects: true }).map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                <p>{t('privacy.data_subject_rights.contact_instructions')}</p>

                <h2>{t('privacy.security_confidentiality.title')}</h2>
                <p>{t('privacy.security_confidentiality.content')}</p>

                <h2>{t('privacy.data_processor.title')}</h2>
                <p>{t('privacy.data_processor.content')}</p>

                <h2>{t('privacy.data_transfer.title')}</h2>
                <p>{t('privacy.data_transfer.content')}</p>

                <h2>{t('privacy.data_protection_officer.title')}</h2>
                <p>{t('privacy.data_protection_officer.content')}</p>

                <h2>{t('privacy.contact_details.title')}</h2>
                <p>{t('privacy.contact_details.content')}</p>
            </div>
        </div>
    );
};

export default Privacy;
