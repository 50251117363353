import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import './OrderStatus.css';
import { FaCheckCircle, FaShippingFast, FaClipboardCheck, FaBan } from 'react-icons/fa';
import { GiCookingPot } from 'react-icons/gi';
import { MdRestaurantMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const OrderStatus = () => {
  const { orderId } = useParams(); 
  const [status, setStatus] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelReason, setCancelReason] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchOrderStatus = async () => {
      const today = dayjs().format('DD-MM-YYYY');
      const orderRef = doc(firestore, `orders/${today}/orders/${orderId}`);
      const unsubscribe = onSnapshot(orderRef, (doc) => {
        if (doc.exists()) {
          const orderData = doc.data();
          setStatus(orderData.status);
          setItems(orderData.items || []);
          if (orderData.status === -1) {
            setCancelReason(orderData.reason || t('status.reason'));
          }
        } else {
          setError(t('status.orderNotFound'));
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchOrderStatus();
  }, [orderId]);

  if (loading) return <p>{t('status.loading')}</p>;
  if (error) return <p>{error}</p>;

  const statuses = status === -1
    ? [
        { id: 0, label: t('status.orderReceived'), icon: <FaClipboardCheck /> },
        { id: -1, label: t('status.orderCanceled'), icon: <FaBan /> },
      ]
    : [
        { id: 0, label: status === 0 ? t("status.orderReceived") : t('status.orderConfirmed'), icon: <FaClipboardCheck /> },
        { id: 2, label: t("status.preparing"), icon: <MdRestaurantMenu /> },
        { id: 3, label: t("status.readyForDelivery"), icon: <GiCookingPot /> },
        { id: 4, label: t('status.outForDelivery'), icon: <FaShippingFast /> },
        { id: 5, label: t('status.delivered'), icon: <FaCheckCircle /> },
      ];

  return (
    <div className="page_orderstatus">
      <h1 className="status-title">{t('status.orderStatusTitle')}</h1>
      <div className="status-container" style={{ flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'center' }}>
        {statuses.map((step, index) => (
          <React.Fragment key={step.id}>
            <motion.div
              className={`status-node ${step.id <= status ? 'active' : ''}`}
              initial={{ scale: 0.9, opacity: 0.5 }}
              animate={{ scale: step.id === status ? 1.2 : 1, opacity: step.id <= status ? 1 : 0.5 }}
              transition={{ type: 'spring', stiffness: 300 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: isMobile ? '10px 0' : '0 10px',
              }}
            >
              <div className="icon" style={{ fontSize: '24px' }}>{step.icon}</div>
            </motion.div>
            {index < statuses.length - 1 && (
              <motion.div
                className="status-line"
                initial={{ width: 0, height: 0 }}
                animate={{ width: isMobile ? '2px' : '50px', height: isMobile ? '50px' : '2px' }}
                transition={{ type: 'spring', stiffness: 300 }}
                style={{
                  backgroundColor: step.id < status ? '#4caf50' : '#ccc',
                  width: isMobile ? '2px' : '50px',
                  height: isMobile ? '50px' : '2px',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="current-status">
        <strong>{t("status.currentStatus")} {status === 1 ? (i18n.language === 'en' ? 'Order Confirmed' : "Comandă acceptată") : statuses.find(s => s.id === status)?.label}</strong>
        {status === -1 && (
          <div className="cancel-reason">
            <p>{t("status.cancelReason")} {cancelReason}</p>
          </div>
        )}
        <div className="order-items">
          <h2>{t("status.orderItemsTitle")}</h2>
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <img src={item.photo} alt={item.title} className="order-item-image" />
                <p>{item.title} x {item.quantity}</p>
                <p>{item.price * item.quantity} RON</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
