import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import { firestore, functions } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './Checkout.css';
import { useAuth } from '../../contexts/AuthContext';
import { images } from '../../constants';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const createOrder = httpsCallable(functions, 'createOrder');
const validateAddress = httpsCallable(functions, 'validateAddress');

const Checkout = () => {
  const { user } = useAuth();
  const { cart, dispatch } = useCart();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    street: '',
    number: '',
    apartment: '',
    localitate: '',
    deliveryType: '',
    paymentMethod: '', // Add paymentMethod to formData
    comments: '', // Add comments to formData
  });
  const [minOrder, setMinOrder] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [costAmbalaje, setCostAmbalaje] = useState(2);
  const [weekdaysOpen, setWeekdaysOpen] = useState('10:00');
  const [weekdaysClose, setWeekdaysClose] = useState('21:30');
  const [weekendOpen, setWeekendOpen] = useState('10:00');
  const [weekendClose, setWeekendClose] = useState('21:30');
  const [dailymenuOpen, setDailymenuOpen] = useState('10:00');
  const [dailymenuClose, setDailymenuClose] = useState('14:00');

  useEffect(() => {
    const itemsCount = cart.reduce((total, item) => total + item.quantity, 0);
    const packagingCost = costAmbalaje * itemsCount;
    setTotalPrice(cart.reduce((total, item) => total + (parseFloat(item.price) * item.quantity), 0) + packagingCost);
  }, [cart, costAmbalaje]);

  useEffect(() => {
    const fetchAmbalajeCost = async () => {
      try {
        const settingsDoc = doc(firestore, 'settings', 'working_hours');
        const docSnap = await getDoc(settingsDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setCostAmbalaje(data.ambalaje || 2);
        }
      } catch (error) {
        console.error("Error fetching ambalaje cost:", error);
      }
    };

    fetchAmbalajeCost();
  }, []);


  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const userDoc = doc(firestore, 'accounts', user.uid);
          const docSnap = await getDoc(userDoc);

          if (docSnap.exists()) {
            const data = docSnap.data();
            setFormData(prevFormData => ({
              ...prevFormData,
              name: data.name || '',
              street: data.street || '',
              number: data.number || '',
              apartment: data.apartment || '',
              localitate: data.localitate || '',
              phone: data.phone || '',
            }));
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchMinOrder = async () => {
      try {
        const settingsDoc = doc(firestore, 'settings', 'working_hours');
        const docSnap = await getDoc(settingsDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setMinOrder(data.min_order || 50);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    }

    fetchUserData();
    fetchMinOrder();
  }, [user]);


  useEffect(() => {
    const fetchWorkingHours = async () => {
      try {
        const settingsDoc = doc(firestore, 'settings', 'working_hours');
        const docSnap = await getDoc(settingsDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setDailymenuOpen(data.dailymenu_open || '10:00');
          setDailymenuClose(data.dailymenu_close || '14:00');
          setWeekdaysOpen(data.weekdays_open || '10:00');
          setWeekdaysClose(data.weekdays_close || '21:30');
          setWeekendOpen(data.weekend_open || '10:00');
          setWeekendClose(data.weekend_close || '21:30');
        }
      } catch (error) {
        console.error("Error fetching working hours:", error);
      }
    };

    fetchWorkingHours();
  }, []);


  const handleRemove = (id) => {
    dispatch({ type: 'REMOVE_FROM_CART', id });
  };

  const handleDecreaseQuantity = (id) => {
    dispatch({ type: 'DECREASE_QUANTITY', id });
  };

  const handleIncreaseQuantity = (item) => {
    dispatch({ type: 'ADD_TO_CART', item });
  };

  const handleClearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isWithinOperatingHours = () => {
    const now = new Date();
    const currentTime = format(now, 'HH:mm');
    const dayOfWeek = now.getDay();

    const isWeekend = (dayOfWeek === 0 || dayOfWeek === 6);
    const openTime = isWeekend ? weekendOpen : weekdaysOpen;
    const closeTime = isWeekend ? weekendClose : weekdaysClose;

    return currentTime >= openTime && currentTime <= closeTime;
  };

  const isWithinDailyMenuHours = () => {
    const now = new Date();
    const currentTime = format(now, 'HH:mm');

    return currentTime >= dailymenuOpen && currentTime <= dailymenuClose;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check working hours
    if (!isWithinOperatingHours()) {
      alert(i18n.language === 'en' ? "We are currently closed. Please come back during our working hours." : "Suntem închis. Vă rugăm să reveniți în timpul programului nostru de lucru.");
      return;
    }

    const hasDailyMenuItem = cart.some(item => item.categoryId === 'meniul_zilei');
    if (!isWithinDailyMenuHours() && hasDailyMenuItem) {
      alert(i18n.language === 'en' ? `Daily menu is available between ${dailymenuOpen} and ${dailymenuClose}.` : `Meniul zilei este disponibil între ${dailymenuOpen} și ${dailymenuClose}.`);
      return;
    }

    if (!formData.deliveryType) {
      alert(i18n.language === 'en' ? "Please select a delivery type." : "Vă rugăm să selectați o metodă de livrare.");
      return;
    }
    if (formData.deliveryType === 'delivery' && !formData.paymentMethod) {
      alert(i18n.language === 'en' ? "Please select a payment method." : "Vă rugăm să selectați o metodă de plată.");
      return;
    }
    if (formData.deliveryType === 'delivery' && totalPrice < minOrder) {
      alert(`${t('checkout.min_order_message')} ${minOrder} RON.`);
      return;
    }

    if (formData.deliveryType === 'delivery') {

      if (formData.street === '' || formData.number === '' || formData.localitate === '') {
        alert(i18n.language === 'en' ? "Please fill in the address fields." : "Vă rugăm să completați câmpurile pentru adresă.");
        return;
      };

      const fullAddress = `${formData.street} ${formData.number}, ${formData.apartment ? `Ap. ${formData.apartment}, ` : ''}${formData.localitate}`;

      const isValidAddress = await validateAddress({ fullAddress: fullAddress });

      if (!(isValidAddress.data)) {
        alert(i18n.language === 'en' ? "Invalid address. We do not deliver to that location." : "Adresă invalidă. Nu livrăm în acea locație.");
        return;
      }
    }

    try {
      const result = await createOrder({
        name: formData.name,
        address: formData.deliveryType === 'delivery' ? `${formData.street} ${formData.number}, ${formData.apartment ? `Ap. ${formData.apartment}, ` : ''}${formData.localitate}` : 'Pickup',
        phone: formData.phone,
        totalPrice: totalPrice,
        items: cart,
        deliveryType: formData.deliveryType,
        paymentMethod: formData.paymentMethod,
        comments: formData.comments,
      });

      const orderId = result.data.orderId;
      handleClearCart();
      navigate(`/order-status/${orderId}`);
    } catch (error) {
      console.error('Error creating order:', error);
      alert(i18n.language === 'en' ? "An error occurred while processing your order. Please try again later." : "A apărut o eroare la procesarea comenzii. Vă rugăm să încercați din nou mai târziu.");
    }

  };

  return (
    <div className="app__checkout section__padding">
      <h1 className="headtext__cormorant">{t('checkout.checkout_title')}</h1>
      {cart.length === 0 ? (
        <p className="p__opensans">{t('checkout.cart_empty')}</p>
      ) : (
        <div className="app__checkout-content">
          <div className="app__checkout-items">
            {cart.map((item, index) => (
              <div key={index} className="app__checkout-item">
                <img src={item.photo} alt={item.title} />
                <div className="app__checkout-item-info">
                  <h3 className="p__cormorant">{item.title}</h3>
                  <p className="p__opensans">{item.description}</p>
                  <p className="p__opensans">Price: {item.price} RON</p>
                  <div className="quantity-controls">
                    <button className="quantity-btn" onClick={() => handleDecreaseQuantity(item.productId)}>-</button>
                    <span className="quantity">{item.quantity}</span>
                    <button className="quantity-btn" onClick={() => handleIncreaseQuantity(item)}>+</button>
                  </div>
                  <button className="custom__button" onClick={() => handleRemove(item.productId)}>{t('checkout.remove')}</button>
                </div>
              </div>
            ))}
          </div>
          <div className="app__checkout-summary">
            <h2 className="headtext__cormorant">{t('checkout.summary_title')}</h2>
            <p className="p__opensans">{t('checkout.total_items')} {cart.reduce((sum, item) => sum + item.quantity, 0)}</p>
            <p className="p__opensans">{t('checkout.cost_ambalaje')} {costAmbalaje * cart.reduce((sum, item) => sum + item.quantity, 0)} RON</p>
            <p className="p__opensans">{t('checkout.total_price')} {totalPrice} RON</p>

            <button className="custom__button" onClick={handleClearCart}>{t('checkout.clear_cart')}</button>
            <div className="app__checkout-payment">
              <h2 className="headtext__cormorant">{t('checkout.payment_information')}</h2>
              <div className="delivery-method">
                <label>
                  <input type="radio" name="deliveryType" value="delivery" required onChange={handleInputChange} />
                  <span className="custom-radio"></span>
                  {t('checkout.delivery_method_delivery')}
                </label>
                <label>
                  <input type="radio" name="deliveryType" value="pickup" required onChange={handleInputChange} />
                  <span className="custom-radio"></span>
                  {t('checkout.delivery_method_pickup')}
                </label>
              </div>

              {formData.deliveryType === 'delivery' && (
                <>
                  <img src={images.spoon} alt="about_spoon" className="spoon__img" />
                  <div className="payment-method">
                    <label>
                      <input type="radio" name="paymentMethod" value="cash" required onChange={handleInputChange} />
                      <span className="custom-radio"></span>
                      {t('checkout.payment_method_cash')}
                    </label>
                    <label>
                      <input type="radio" name="paymentMethod" value="card" required onChange={handleInputChange} />
                      <span className="custom-radio"></span>
                      {t('checkout.payment_method_card')}
                    </label>
                  </div>

                  <input
                    type="text"
                    name="street"
                    placeholder={t('account.form.street')}
                    className="p__opensans"
                    required
                    value={formData.street}
                    onChange={handleInputChange}
                    style={{ color: 'black', width: '80%', marginBottom: '1rem', padding: '0.5rem', fontSize: '16px' }}
                  />

                  <input
                    type="text"
                    name="number"
                    placeholder={t('account.form.number')}
                    className="p__opensans"
                    required
                    value={formData.number}
                    onChange={handleInputChange}
                    style={{ color: 'black', width: '80%', marginBottom: '1rem', padding: '0.5rem', fontSize: '16px' }}
                  />

                  <input
                    type="text"
                    name="apartment"
                    placeholder={t('account.form.apartment')}
                    className="p__opensans"
                    value={formData.apartment}
                    onChange={handleInputChange}
                    style={{ color: 'black', width: '80%', marginBottom: '1rem', padding: '0.5rem', fontSize: '16px' }}
                  />

                  <input
                    type="text"
                    name="localitate"
                    placeholder={t('account.form.localitate')}
                    className="p__opensans"
                    required
                    value={formData.localitate}
                    onChange={handleInputChange}
                    style={{ color: 'black', width: '80%', marginBottom: '1rem', padding: '0.5rem', fontSize: '16px' }}
                  />

                  {totalPrice < minOrder && (
                    <p className="p__opensans" style={{ color: 'red' }}>
                      Minimum order amount for delivery is {minOrder} RON.
                    </p>
                  )}
                  <br />
                  <img src={images.spoon} alt="about_spoon" className="spoon__img" />
                  <br />
                </>
              )}

              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  placeholder={t('checkout.name_placeholder')}
                  className="p__opensans"
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="phone"
                  placeholder={t('checkout.phone_placeholder')}
                  className="p__opensans"
                  required
                  value={formData.phone}
                  onChange={handleInputChange}
                />
                <textarea
                  name="comments"
                  placeholder={t('checkout.comments_placeholder')}
                  className="p__opensans"
                  value={formData.comments}
                  onChange={handleInputChange}
                  style={{ minHeight: '100px' }} // Adjust the height as needed
                />

                <button type="submit" className="custom__button">{t('checkout.submit_payment')}</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
