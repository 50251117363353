import React, { useEffect, useState } from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../../firebase';
import { useTranslation } from 'react-i18next';

const FindUs = () => {
  const [workingHours, setWorkingHours] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchWorkingHours = async () => {
      const docRef = doc(firestore, "settings", "working_hours");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setWorkingHours(docSnap.data());
      }
    };

    fetchWorkingHours();
  }, []);

  if (!workingHours) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app__bg app__wrapper section__padding" id="contact">
      <div className="app__wrapper_info">
        <SubHeading title="Contact" />
        <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>{t("contact.title")}</h1>
        <div className="app__wrapper-content">
          <p className="p__opensans">{t("contact.address")}:</p>
          <p className="p__opensans">strada Franyo Zoltan 17, Dumbravita 307160, judet Timis</p>
          <p className="p__opensans">{t("contact.phone")}:</p>
          <p className="p__opensans">{workingHours.phone}</p>
          <p className="p__opensans">{workingHours.phone2}</p>
          <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>{t("contact.opening_hours")}</p>
          <p className="p__opensans">{t("contact.weekdays").replace('{weekdays_open}', workingHours.weekdays_open).replace('{weekdays_close}', workingHours.weekdays_close)}</p>
          <p className="p__opensans">{t("contact.weekend").replace('{weekend_open}', workingHours.weekend_open).replace('{weekend_close}', workingHours.weekend_close)}</p>
        </div>

        <button className="custom__button" style={{ marginTop: '2rem' }}>{t("contact.button_text")}</button>
      </div>
      <div className="app__wrapper_img">
        <img src={images.findus} alt="find us" />
      </div>
    </div>
  );

}

export default FindUs;
