import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="g letter" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">{t('aboutus.heading1')}</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans" style={{textTransform: 'none'}}>{t('aboutus.description1')}</p>
        <button type="button" className="custom__button">{t('aboutus.knowmore')}</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt='about_knife' />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">{t('aboutus.heading2')}</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans" style={{textTransform: 'none'}}>{t('aboutus.description2')}</p>
        <button type="button" className="custom__button">{t('aboutus.knowmore')}</button>
      </div>
    </div>
  </div>
);

}

export default AboutUs;
