import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { CartProvider } from './contexts/CartContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import Admin from './pages/Admin/Admin';
import Checkout from './pages/Checkout/Checkout';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Account from './pages/Account/Account';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import OrderStatus from './pages/OrderStatus/OrderStatus';
import { Navbar } from './components';
import { Footer } from './container';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import CancellationPolicy from './pages/Cancellation/CancellationPolicy';
import ManagementOrders from './pages/Management/ManagementOrders';
import MenuQR from './pages/MenuQR/MenuQR';
import './App.css';

const App = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin') || location.pathname.startsWith('/management');
  const { user, isAdmin, isManager } = useAuth();

  const PrivateRoute = ({ children }) => {
    if (user) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  };

  const PublicRoute = ({ children }) => {
    if (user) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  };

  const PrivateAdminRoute = ({ children }) => {
    if (!user || !isAdmin) {
      return <Navigate to="/" />;
    }

    return children;
  }

  const PrivateManagerRoute = ({ children }) => {
    if (!user || !isManager) {
      return <Navigate to="/" />;
    }

    return children;
  }

  return (
    <>
      {!isAdminRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        } />
        <Route path="/login" element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route path="/forgot-password" element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        } />
        <Route path="/account" element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        } />
        <Route path="/menu" element={<Menu />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/menu-qr" element={<MenuQR />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cancellation" element={<CancellationPolicy />} />
        <Route path="/admin" element={<Navigate to="/admin/general" />} />
        <Route path="/admin/*" element={
          <PrivateAdminRoute>
            <Admin />
          </PrivateAdminRoute>
        } />
        <Route path="/order-status/:orderId" element={<OrderStatus />} />
        <Route path="/management/orders" element={
          <PrivateManagerRoute>
            <ManagementOrders />
          </PrivateManagerRoute>
        } />
      </Routes>
      {!isAdminRoute && <Footer />}
    </>
  );
};

const AppWrapper = () => (
  <CartProvider>
    <AuthProvider>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  </CartProvider>
);

export default AppWrapper;
