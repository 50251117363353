import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Adjust the import based on your file structure
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, TextareaAutosize
} from '@mui/material';
import './MeniulZilei.css';

const daysOfWeek = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri'];

const getWeekDays = (date) => {
  const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));
  const weekDays = [startOfWeek];
  for (let i = 1; i < 5; i++) {
    const nextDay = new Date(startOfWeek);
    nextDay.setDate(startOfWeek.getDate() + i);
    weekDays.push(nextDay);
  }
  return weekDays;
};

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const MeniulZilei = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [menu, setMenu] = useState({});

  useEffect(() => {
    fetchMenu();
  }, [currentDate]);

  const fetchMenu = async () => {
    const weekDays = getWeekDays(new Date(currentDate));
    const newMenu = {};
    for (const day of weekDays) {
      const formattedDate = formatDate(day);
      const docRef = doc(firestore, 'dailymenu', formattedDate);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        newMenu[formattedDate] = docSnap.data();
      } else {
        newMenu[formattedDate] = {};
      }
    }
    setMenu(newMenu);
  };

  const handleInputChange = (date, variant, value) => {
    setMenu((prevMenu) => ({
      ...prevMenu,
      [date]: {
        ...prevMenu[date],
        [variant]: value,
      },
    }));
  };

  const saveMenu = async () => {
    const weekDays = getWeekDays(new Date(currentDate));
    for (const day of weekDays) {
      const formattedDate = formatDate(day);
      const dayMenu = menu[formattedDate] || {};

      const docRef = doc(firestore, 'dailymenu', formattedDate);
      const docSnap = await getDoc(docRef);

      const isEmptyMenu = !dayMenu.varianta1 && !dayMenu.varianta2 && !dayMenu.varianta3;

      if (isEmptyMenu){
        if (docSnap.exists()) {
          await setDoc(docRef, {});
          await deleteDoc(docRef);
        }
        continue;
      }

      const filteredMenu = Object.keys(dayMenu).reduce((acc, key) => {
        if (dayMenu[key]) {
          acc[key] = dayMenu[key];
        }
        return acc;
      }, {});

      if (!docSnap.exists() && Object.keys(filteredMenu).length > 0) {
        filteredMenu.orders = [0, 0, 0];
      }

      if (Object.keys(filteredMenu).length > 0) {
        await setDoc(docRef, filteredMenu, { merge: true });
      }
    }
    alert('Meniul a fost salvat cu succes!');
  };

  const navigateWeek = (direction) => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + direction * 7);
      return newDate;
    });
  };

  const printTable = () => {
    const printContent = document.querySelector('.printable-content').outerHTML;
    const originalContent = document.body.innerHTML;
    
    document.body.innerHTML = printContent;
    window.print();
    
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  const weekDays = getWeekDays(new Date(currentDate));
  const { startOfWeek, endOfWeek } = { startOfWeek: weekDays[0], endOfWeek: weekDays[4] };

  return (
    <div className="printable-content">
      <h2 className="week-title">
        Saptamana {formatDate(startOfWeek)} - {formatDate(endOfWeek)}
      </h2>
      <div className="week-navigation">
        <Button variant="contained" onClick={() => navigateWeek(-1)}>&lt;</Button>
        <Button variant="contained" onClick={() => navigateWeek(1)}>&gt;</Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="table-cell">Ziua</TableCell>
              <TableCell className="table-cell">Varianta 1</TableCell>
              <TableCell className="table-cell">Varianta 2</TableCell>
              <TableCell className="table-cell">Varianta 3</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weekDays.map((day, index) => {
              const formattedDate = formatDate(day);
              return (
                <TableRow key={index}>
                  <TableCell className="table-cell">{daysOfWeek[index]}</TableCell>
                  <TableCell className="table-cell">
                    <TextareaAutosize
                      className="textarea"
                      minRows={3}
                      value={menu[formattedDate]?.varianta1 || ''}
                      onChange={(e) => handleInputChange(formattedDate, 'varianta1', e.target.value)}
                    />
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextareaAutosize
                      className="textarea"
                      minRows={3}
                      value={menu[formattedDate]?.varianta2 || ''}
                      onChange={(e) => handleInputChange(formattedDate, 'varianta2', e.target.value)}
                    />
                  </TableCell>
                  <TableCell className="table-cell">
                    <TextareaAutosize
                      className="textarea"
                      minRows={3}
                      value={menu[formattedDate]?.varianta3 || ''}
                      onChange={(e) => handleInputChange(formattedDate, 'varianta3', e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={saveMenu}>Salvează</Button>
      <Button variant="contained" color="secondary" onClick={printTable}>Printează</Button>
    </div>
  );
};

export default MeniulZilei;
