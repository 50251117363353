// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import { useTranslation } from 'react-i18next';
import './Login.css';

const Login = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // Redirect to home page or any other page
    } catch (error) {
      const errorMessage = t(`login.errors.${error.code}`) || t('login.errors.default');
      setError(errorMessage);
    }
  };

  return (
    <div className="app__login section__padding">
      <h1 className="headtext__cormorant">{t('login.login')}</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit} className="app__login-form">
        <input
          type="email"
          name="email"
          placeholder={t('login.email')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder={t('login.password')}
          className="p__opensans"
          style= {{textTransform: 'none'}}
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <button type="submit" className="custom__button">{t('login.login')}</button>
      </form>
      <p className="p__opensans">
        {t('login.noAccount')} <button className="link-button" onClick={() => navigate('/register')}>{t('login.register')}</button>
      </p>
      <p className="p__opensans">
        <button className="link-button" onClick={() => navigate('/forgot-password')}>{t('login.forgotPassword')}</button>
      </p>
    </div>
  );
};

export default Login;
