import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import './Setari.css';

const Setari = () => {
  const [settings, setSettings] = useState({
    ambalaje: '',
    dailymenu_close: '',
    dailymenu_open: '',
    email: '',
    min_order: '',
    phone: '',
    phone2: '',
    weekdays_close: '',
    weekdays_open: '',
    weekend_close: '',
    weekend_open: '',
    whatsapp: '',
  });

  const settingsRef = doc(firestore, "settings", "working_hours");

  useEffect(() => {
    const fetchSettings = async () => {
      const docSnap = await getDoc(settingsRef);
      if (docSnap.exists()) {
        setSettings(docSnap.data());
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSaveSettings = async () => {
    await updateDoc(settingsRef, settings);
    alert("Settings have been saved successfully!");
  };

  return (
    <div className="settings">
      <h1>Setări</h1>
      <div className="settings__grid">
        <div className="setting-group">
          <label>Cost ambalaje:</label>
          <input
            type="number"
            name="ambalaje"
            value={settings.ambalaje}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Meniul zilei începe la:</label>
          <input
            type="time"
            name="dailymenu_open"
            value={settings.dailymenu_open}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Meniul zilei se încheie la:</label>
          <input
            type="time"
            name="dailymenu_close"
            value={settings.dailymenu_close}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={settings.email}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Valoarea comenzii minime la livrare:</label>
          <input
            type="number"
            name="min_order"
            value={settings.min_order}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Telefon primar:</label>
          <input
            type="tel"
            name="phone"
            value={settings.phone}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Telefon secundar:</label>
          <input
            type="tel"
            name="phone2"
            value={settings.phone2}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Telefon WhatsApp:</label>
          <input
            type="tel"
            name="whatsapp"
            value={settings.whatsapp}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Deschidere în timpul săptămânii:</label>
          <input
            type="time"
            name="weekdays_open"
            value={settings.weekdays_open}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Închidere în timpul săptămânii:</label>
          <input
            type="time"
            name="weekdays_close"
            value={settings.weekdays_close}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Deschidere în weekend:</label>
          <input
            type="time"
            name="weekend_open"
            value={settings.weekend_open}
            onChange={handleChange}
          />
        </div>
        <div className="setting-group">
          <label>Închidere în weekend:</label>
          <input
            type="time"
            name="weekend_close"
            value={settings.weekend_close}
            onChange={handleChange}
          />
        </div>
      </div>
      <button className="save-button" onClick={handleSaveSettings}>Salvează</button>
    </div>
  );
};

export default Setari;
