import React, { useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase'; // Ensure auth is imported
import './Conturi.css';
import { FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Conturi = () => {
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccounts = async () => {
      const accountsCollection = collection(firestore, 'accounts');
      const accountsSnapshot = await getDocs(accountsCollection);

      const accountsData = await Promise.all(accountsSnapshot.docs.map(async (accountDoc) => {
        const accountData = accountDoc.data();
        const accountId = accountDoc.id;

        // Calculate the number of orders
        let totalOrders = 0;
        const ordersCollection = collection(firestore, `accounts/${accountId}/orders`);
        const ordersSnapshot = await getDocs(ordersCollection);

        ordersSnapshot.docs.forEach(doc => {
          totalOrders += Object.keys(doc.data()).length;
        });

        return {
          id: accountId,
          name: accountData.name || 'N/A',
          phone: accountData.phone || 'N/A',
          mail: accountData.email || 'N/A',
          address: accountData.street
            ? `${accountData.street} ${accountData.number || ''}, ${accountData.apartment ? `Ap. ${accountData.apartment}, ` : ''}${accountData.localitate || ''}`
            : 'N/A',
          totalOrders,
        };
      }));

      setAccounts(accountsData);
    };

    fetchAccounts();
  }, []);

  const handleDetailsClick = (accountId) => {
    navigate(`/admin/conturi/${accountId}`);
  }

  return (
    <div className="conturi">
      <h1>Conturi</h1>
      <table className="conturi-table">
        <thead>
          <tr>
            <th>Nume</th>
            <th>Telefon</th>
            <th>Mail</th>
            <th>Adresa</th>
            <th>Nr. Comenzi</th>
            <th>Detalii</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map(account => (
            <tr key={account.id}>
              <td>{account.name}</td>
              <td>{account.phone}</td>
              <td>{account.mail}</td>
              <td>{account.address}</td>
              <td>{account.totalOrders}</td>
              <td>
                <FaInfoCircle className="details-icon"
                  onClick={() => handleDetailsClick(account.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Conturi;
