import React from 'react';
import './CancellationPolicy.css';
import { useTranslation } from 'react-i18next';

const CancellationPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="cancellation-policy__container">
            <h1 className="cancellation-policy__title">{t('cancellation.title')}</h1>

            <div className="cancellation-policy__section">
                <h2 className="cancellation-policy__section-title">{t('cancellation.introduction.title')}</h2>
                <p className="cancellation-policy__section-content">{t('cancellation.introduction.content')}</p>
            </div>

            <div className="cancellation-policy__section">
                <h2 className="cancellation-policy__section-title">{t('cancellation.order_cancellation.title')}</h2>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.order_cancellation.how_to_cancel.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.order_cancellation.how_to_cancel.content')}</p>
                </div>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.order_cancellation.after_processing.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.order_cancellation.after_processing.content')}</p>
                </div>
            </div>

            <div className="cancellation-policy__section">
                <h2 className="cancellation-policy__section-title">{t('cancellation.return_policy.title')}</h2>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.return_policy.conditions.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.return_policy.conditions.content')}</p>
                </div>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.return_policy.procedure.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.return_policy.procedure.content')}</p>
                </div>
            </div>

            <div className="cancellation-policy__section">
                <h2 className="cancellation-policy__section-title">{t('cancellation.refunds.title')}</h2>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.refunds.procedure.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.refunds.procedure.content')}</p>
                </div>

                <div className="cancellation-policy__subsection">
                    <h3 className="cancellation-policy__subsection-title">{t('cancellation.refunds.method.title')}</h3>
                    <p className="cancellation-policy__subsection-content">{t('cancellation.refunds.method.content')}</p>
                </div>
            </div>

            <div className="cancellation-policy__section">
                <h2 className="cancellation-policy__section-title">{t('cancellation.contact_support.title')}</h2>
                <p className="cancellation-policy__section-content">{t('cancellation.contact_support.content')}</p>
                <p className="cancellation-policy__section-content"><strong>{t('cancellation.contact_support.phone')}</strong></p>
                <p className="cancellation-policy__section-content"><strong>{t('cancellation.contact_support.email')}</strong></p>
            </div>
        </div>
    );
};

export default CancellationPolicy;
