import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './OrderDetails.css';

const OrderDetails = () => {
  const { date, orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const orderRef = doc(firestore, 'orders', date, 'orders', orderId);
      const orderSnap = await getDoc(orderRef);

      if (orderSnap.exists()) {
        setOrder(orderSnap.data());
      }
      setLoading(false);
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!order) {
    return <div>Order not found.</div>;
  }

  return (
    <div className="order-details">
      <h1>Order Details</h1>
      <div className="order-panel">
        <h2>Customer Information</h2>
        <p><strong>Name:</strong> {order.name}</p>
        <p><strong>Phone:</strong> {order.phone}</p>
        <p><strong>Address:</strong> {order.address}</p>
        <p><strong>Comments:</strong> {order.comments || 'No comments'}</p>
        <p><strong>Delivery Type:</strong> {order.deliveryType}</p>

        <h2>Order Information</h2>
        <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
        <p><strong>Total Price:</strong> {order.totalPrice} RON</p>
        <p><strong>Status:</strong> {renderStatus(order.status)}</p>
        <p><strong>Created At:</strong> {new Date(order.createdAt).toLocaleString()}</p>

        <h2>Items</h2>
        <div className="items-list">
          {order.items.map((item, index) => (
            <div key={index} className="item">
              <img src={item.photo} alt={item.title} className="item-photo" />
              <div>
                <p><strong>Title:</strong> {item.title}</p>
                <p><strong>Quantity:</strong> {item.quantity}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const renderStatus = (status) => {
  switch (status) {
    case -1:
      return 'COMANDA ANULATA';
    case 0:
      return 'COMANDA PRIMITA';
    case 1:
      return 'COMANDA ACCEPTATA';
    case 2:
      return 'COMANDA SE PREGATESTE';
    case 3:
      return 'COMANDA SE GATESTE';
    case 4:
      return 'COMANDA SE LIVREAZA';
    case 5:
      return 'COMANDA FINALIZATA';
    default:
      return 'STATUS NECUNOSCUT';
  }
};

export default OrderDetails;
