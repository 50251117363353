import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { firestore } from '../../firebase';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Import an icon for "Detalii"
import { useNavigate } from 'react-router-dom';
import './Comenzi.css';

const Comenzi = () => {
  const [orders, setOrders] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const navigate = useNavigate();

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatFirestoreDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleDateChange = (offset) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + offset);
    setCurrentDate(newDate);
  };

  const renderStatus = (order) => {
    switch (order.status) {
      case -1:
        return 'COMANDA ANULATA';
      case 0:
        return 'COMANDA PRIMITA';
      case 1:
        return 'COMANDA ACCEPTATA';
      case 2:
        return 'COMANDA SE PREGATESTE';
      case 3:
        return 'COMANDA SE GATESTE';
      case 4:
        return order.deliveryType === 'delivery' ? 'COMANDA DE LIVRAT' : 'COMANDA DE RIDICAT';
      case 5:
        return 'COMANDA FINALIZATA';
      default:
        return 'STATUS NECUNOSCUT';
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const formattedDate = formatFirestoreDate(currentDate);
      const ordersCollection = collection(firestore, "orders", formattedDate, "orders");
      const dateOrdersSnapshot = await getDocs(query(ordersCollection, orderBy("createdAt", "desc")));
      const allOrders = dateOrdersSnapshot.docs.map((orderDoc) => ({
        id: orderDoc.id,
        ...orderDoc.data(),
      }));
      setOrders(allOrders);
    };

    fetchOrders();
  }, [currentDate]);

  const handleDetailsClick = (orderId) => {
    navigate(`/admin/comenzi/${formatDate(currentDate)}/${orderId}`);
  };

  return (
    <div className="comenzi">
      <h1>Comenzi</h1>
      <div className="date-navigation">
        <button onClick={() => handleDateChange(-1)}>{"<"}</button>
        <span>{formatDate(currentDate)}</span>
        <button onClick={() => handleDateChange(1)}>{">"}</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>Timp</th>
            <th>Nume</th>
            <th>Telefon</th>
            <th>Status</th>
            <th>Total</th>
            <th>Detalii</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => (
            <tr key={order.id}>
              <td>{order.createdAt}</td>
              <td>{order.name}</td>
              <td>{order.phone}</td>
              <td>{renderStatus(order)}</td>
              <td>{order.totalPrice} RON</td>
              <td>
                <AiOutlineInfoCircle
                  className='info-icon'
                  onClick={() => handleDetailsClick(order.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Comenzi;
