import React, { useEffect, useState } from 'react';
import { images } from "../../constants";
import { SubHeading, MenuItem } from '../../components';
import './SpecialMenu.css';
import { useTranslation } from 'react-i18next';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';

const SpecialMenu = () => {
  const [menuData, setMenuData] = useState({ wines: [], cocktails: [] });
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMenuData();
        setMenuData(data);
      } catch (error) {
        console.error('Failed to fetch menu data');
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const fetchMenuData = async () => {
    const wineBeerCollection = collection(firestore, 'wine_beer');
    const cocktailsCollection = collection(firestore, 'alcohol');

    const wineBeerSnap = await getDocs(wineBeerCollection);
    const cocktailsSnap = await getDocs(cocktailsCollection);

    const wines = wineBeerSnap.docs.map(doc => doc.data());
    const cocktails = cocktailsSnap.docs.map(doc => doc.data());

    return { wines, cocktails };
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title={t("specialMenu.title")} />
        <h1 className="headtext__cormorant">{t("specialMenu.drinks")}</h1>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_wine flex__center">
          <p className="app__specialMenu-menu_heading">{t("specialMenu.wine_and_beer")}</p>
          <div className="app__specialMenu_menu_items">
            {menuData.wines.map((wine, index) => (
              <MenuItem 
                key={wine.name + index} 
                title={wine.name} 
                price={wine.price + "RON"} 
                tags={wine.tags.toLowerCase() === "bottle" && i18n.language === 'ro' ? 'Sticlă' : wine.tags} />
            ))}
          </div>
        </div>

        <div className="app__specialMenu-menu_img">
          <img src={images.menu} alt="menu img" />
        </div>

        <div className="app__specialMenu-menu_cocktails flex__center">
          <p className="app__specialMenu-menu_heading">{t("specialMenu.cocktails")}</p>
          <div className="app__specialMenu_menu_items">
            {menuData.cocktails.map((cocktail, index) => (
              <MenuItem key={cocktail.name + index} title={cocktail.name} price={cocktail.price + "RON"} tags={cocktail.tags} />
            ))}
          </div>
        </div>

      </div>

      <div style={{ marginTop: '15px' }}>
        <button type="button" className="custom__button">{t("specialMenu.view_more")}</button>
      </div>
    </div>
  );

}

export default SpecialMenu;
