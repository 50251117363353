import React from 'react';
import './Terms.css';
import { useTranslation } from 'react-i18next';

const Terms = () => {
    const { t } = useTranslation();
    return (
        <div className="terms__container">
            <h1 className="terms__title">{t('terms.title')}</h1>
            <div className="terms__content">
                <h2>{t('terms.definitions.title')}</h2>
                <p>{t('terms.definitions.parties')}</p>
                <p><strong>{t('terms.definitions.partner_restaurant')}</strong> {t('terms.definitions.partner_restaurant_2')}</p>
                <p><strong>{t('terms.definitions.client')}</strong> {t('terms.definitions.client_2')}</p>
                <p><strong>{t('terms.definitions.user')}</strong> {t('terms.definitions.user_2')}</p>
                <p><strong>{t('terms.definitions.it_system')}</strong>{t('terms.definitions.it_system_2')}</p>
                <p><strong>{t('terms.definitions.order')}</strong>{t('terms.definitions.order_2')}</p>
                <p><strong>{t('terms.definitions.payment')}</strong> {t('terms.definitions.payment_2')}</p>
                <p><strong>{t('terms.definitions.data_administrator')}</strong>{t('terms.definitions.data_administrator_2')}</p>

                <h2>{t('terms.general_provisions.title')}</h2>
                <p>{t('terms.general_provisions.service_provision')}</p>
                <p>{t('terms.general_provisions.target_audience')}</p>
                <p>{t('terms.general_provisions.obligations')}</p>
                <p>{t('terms.general_provisions.information')}</p>
                <p>{t('terms.general_provisions.prohibition')}</p>
                <p>{t('terms.general_provisions.illegal_content')}</p>
                <p>{t('terms.general_provisions.client_becomes_user')}</p>
                <p>{t('terms.general_provisions.client_data')}</p>
                <p>{t('terms.general_provisions.illegal_data_use')}</p>
                <p>{t('terms.general_provisions.consent')}</p>

                <h2>{t('terms.order_acceptance.title')}</h2>
                <p>{t('terms.order_acceptance.it_services_usage')}</p>
                <p>{t('terms.order_acceptance.order_conditions')}</p>
                <p>{t('terms.order_acceptance.order_confirmation')}</p>

                <h2>{t('terms.technical_requirements.title')}</h2>
                <p>{t('terms.technical_requirements.device_requirements')}</p>
                <p>{t('terms.technical_requirements.browser_requirements')}</p>
                <p>{t('terms.technical_requirements.privacy_policy')}</p>
                <p>{t('terms.technical_requirements.browser_requirements_detail')}</p>

                <h2>{t('terms.additional_information.title')}</h2>
                <p>{t('terms.additional_information.contract')}</p>
                <p>{t('terms.additional_information.right_to_cancel_')}</p>
                <p>{t('terms.additional_information.right_to_cancel_1')}</p>
                <p>{t('terms.additional_information.right_to_cancel_2')}</p>
                <p>{t('terms.additional_information.right_to_cancel_3')}</p>
                <p>{t('terms.additional_information.right_to_cancel_4')}</p>
                <p>{t('terms.additional_information.public_information')}</p>
                <p>{t('terms.additional_information.confirmation_display')}</p>
                <p>{t('terms.additional_information.execution_time')}</p>

                <h2>{t('terms.payment_methods.title')}</h2>
                <p>{t('terms.payment_methods.available_methods')}</p>
                <p>{t('terms.payment_methods.payment_settlement')}</p>
                <p>{t('terms.payment_methods.online_payment_confirmation')}</p>
                <p>{t('terms.payment_methods.order_cancellation')}</p>
                <p>{t('terms.payment_methods.refund_period')}</p>
                <p>{t('terms.payment_methods.cash_payment')}</p>
                <p>{t('terms.payment_methods.payment_refusal')}</p>
                <p>{t('terms.payment_methods.no_liability')}</p>
                <p>{t('terms.payment_methods.documentation')}</p>
                <p>{t('terms.payment_methods.inquiries')}</p>

                <h2>{t('terms.right_of_withdrawal.title')}</h2>
                <p>{t('terms.right_of_withdrawal.withdrawal_conditions')}</p>
                <p>{t('terms.right_of_withdrawal.withdrawal_procedure')}</p>
                <p>{t('terms.right_of_withdrawal.online_payment_refund')}</p>

                <h2>{t('terms.intellectual_property.title')}</h2>
                <p>{t('terms.intellectual_property.system_structure')}</p>
                <p>{t('terms.intellectual_property.content_copying')}</p>
                <p>{t('terms.intellectual_property.no_distribution')}</p>

                <h2>{t('terms.claims.title')}</h2>
                <p>{t('terms.claims.order_claims')}</p>
                <p>{t('terms.claims.restaurant_claims')}</p>
                <p>{t('terms.claims.restaurant_review')}</p>
                <p>{t('terms.claims.agent_claims')}</p>
                <p>{t('terms.claims.agent_review')}</p>
                <p>{t('terms.claims.claim_details')}</p>
                <p>{t('terms.claims.incomplete_claim')}</p>
                <p>{t('terms.claims.claim_review_time')}</p>
                <p>{t('terms.claims.decision_communication')}</p>

                <h2>{t('terms.reviews.title')}</h2>
                <p>{t('terms.reviews.possibility_to_review')}</p>
                <p>{t('terms.reviews.reviews_public')}</p>
                <p>{t('terms.reviews.client_name')}</p>
                <p>{t('terms.reviews.non_anonymous')}</p>
                <p>{t('terms.reviews.delete_review')}</p>

                <h2>{t('terms.data_protection.title')}</h2>
                <p>{t('terms.data_protection.data_protection_details')}</p>

                <h2>{t('terms.risk_description.title')}</h2>
                <p>{t('terms.risk_description.spam_risk')}</p>
                <p>{t('terms.risk_description.malware_risk')}</p>
                <p>{t('terms.risk_description.security_breach_risk')}</p>
                <p>{t('terms.risk_description.spyware_risk')}</p>
                <p>{t('terms.risk_description.unauthorized_software_risk')}</p>

                <h2>{t('terms.final_provisions.title')}</h2>
                <p>{t('terms.final_provisions.applicable_law')}</p>
                <p>{t('terms.final_provisions.amendment_rights')}</p>
                <p>{t('terms.final_provisions.consumer_rights')}</p>
                <p>{t('terms.final_provisions.illegal_content')}</p>
                <ul>
                    <li>{t('terms.final_provisions.illegal_content_list_1')}</li>
                    <li>{t('terms.final_provisions.illegal_content_list_2')}</li>
                    <li>{t('terms.final_provisions.illegal_content_list_3')}</li>
                </ul>
            </div>
        </div>
    );
};

export default Terms;
