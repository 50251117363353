// src/components/Navbar.js
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { HashLink as Link } from 'react-router-hash-link';
import images from '../../constants/images';
import { useTranslation } from 'react-i18next';
import './Navbar.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { BsCart } from 'react-icons/bs';
import { useCart } from '../../contexts/CartContext';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const { cart } = useCart();
  const totalItemsInCart = cart.reduce((total, item) => total + item.quantity, 0);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <Link smooth to="/">
          <img src={images.genesa} alt="app logo" />
        </Link>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <Link smooth to="/#home">{t('navbar.home')}</Link>
        </li>
        <li className="p__opensans">
          <Link smooth to="/#about">{t('navbar.about')}</Link>
        </li>
        <li className="p__opensans">
          <Link smooth to="/menu">{t('navbar.menu')}</Link>
        </li>
        <li className="p__opensans">
          <Link smooth to="/checkout">{t('navbar.cart')} ({totalItemsInCart})</Link>
        </li>
        <li className="p__opensans">
          <Link smooth to="/#contact">{t('navbar.contact')}</Link>
        </li>
      </ul>
      <div className="app__navbar-icons">
        <div className="app__navbar-cart">
          <Link smooth to="/checkout" className="cart-icon">
            <BsCart size={24} />
            <span>{totalItemsInCart}</span>
          </Link>
        </div>
        <div className="app__navbar-login">
          {user ? (
            <>
              <FaUserCircle size={24} className="user-icon" />
              <Link smooth to="/account" className="p__opensans">{t('navbar.account')}</Link>
            </>
          ) : (
            <Link smooth to="/login" className="p__opensans">{t('navbar.login')}</Link>
          )}
          { /* 
        <div />
        <Link smooth to="/" className="p__opensans">{t('navbar.bookRoom')}</Link>
        */}
        </div>
        <div className="app__navbar-language">
          <span
            className={`p__opensans ${i18n.language === 'ro' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('ro')}
          >
            RO
          </span>
          <span className="divider">|</span>
          <span
            className={`p__opensans ${i18n.language === 'en' ? 'active' : ''}`}
            onClick={() => handleLanguageChange('en')}
          >
            EN
          </span>
        </div>
      </div>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu color="#fff" fontSize={27} onClick={handleToggleMenu} />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={handleToggleMenu} />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <Link smooth to="/#home" onClick={handleToggleMenu}>{t('navbar.home')}</Link>
              </li>
              <li className="p__opensans">
                <Link smooth to="/#about" onClick={handleToggleMenu}>{t('navbar.about')}</Link>
              </li>
              <li className="p__opensans">
                <Link smooth to="/menu" onClick={handleToggleMenu}>{t('navbar.menu')}</Link>
              </li>
              <li className="p__opensans">
                <Link smooth to="/checkout" onClick={handleToggleMenu}>{t('navbar.cart')} ({totalItemsInCart})</Link>
              </li>
              <li className="p__opensans">
                <Link smooth to="/#contact" onClick={handleToggleMenu}>{t('navbar.contact')}</Link>
              </li>
              <li className="app__navbar-smallscreen_language">
                <span
                  className={`p__opensans ${i18n.language === 'ro' ? 'active' : ''}`}
                  onClick={() => handleLanguageChange('ro')}
                >
                  RO
                </span>
                <span className="divider">|</span>
                <span
                  className={`p__opensans ${i18n.language === 'en' ? 'active' : ''}`}
                  onClick={() => handleLanguageChange('en')}
                >
                  EN
                </span>
              </li>

              <li className="p__opensans">
                {user ? (
                  <Link smooth to="/account" onClick={handleToggleMenu}>{t('navbar.account')}</Link>
                ) : (
                  <Link smooth to="/login" onClick={handleToggleMenu}>{t('navbar.login')}</Link>
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
